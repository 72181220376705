import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import NavJson from '../../../../../assets/data/nav.json';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-menu]',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
    title = 'menu';
    public navJson = NavJson.nav;

    @Output() menuItemClicked = new EventEmitter<string>();

    constructor(private sanitizer: DomSanitizer) { }

    getSafeLink(): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl('javascript:void(0);');
    }

    onClick() {
        this.menuItemClicked.emit();
    }
}
