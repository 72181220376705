<app-page-title pageTitle="{{title}}"></app-page-title>

<section id="about-me">
    <div class="section">
        <div class="title">
            <div>
                <h1 [innerText]="subtitle"></h1>
            </div>
        </div>
        <div class="text">
            <p [innerHTML]="text"></p>
        </div>
    </div>
</section>

<app-footer></app-footer>