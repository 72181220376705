import { Component, ViewChild, ElementRef } from '@angular/core';

import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { faVolumeOff, faVolumeMute, faVolumeUp, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { faSpotify, faItunes } from '@fortawesome/free-brands-svg-icons';

import { AudioService } from '../../services/audio.service.js';
import { StreamState } from '../../interfaces/stream-state.js';

import MusicReleaseJson from '../../../../assets/data/home/music.json';

@Component({
    selector: 'app-music-player',
    templateUrl: './music-player.component.html',
    styleUrls: ['./music-player.component.scss']
})
export class MusicPlayerComponent {
    title = 'music-player';
    musicTitles = MusicReleaseJson.music;
    header = MusicReleaseJson.title;
    description = MusicReleaseJson.description;
    text = MusicReleaseJson.text.join('');
    isIos: boolean;

    faPlay = faPlay;
    faPause = faPause;
    faVolumeOff = faVolumeOff;
    faVolumeMute = faVolumeMute;
    faVolumeUp = faVolumeUp;
    faSpotify = faSpotify;
    faItunes = faItunes;

    files: Array<any> = [];
    state: StreamState;
    currentFile: any = {};

    constructor(private audioService: AudioService, private deviceService: DeviceDetectorService) {
        this.isIos = this.deviceService.os === 'iOS' || (this.deviceService.os === 'Mac' && this.deviceService.isTablet());

        this.files = Object.assign([], this.musicTitles);

        this.files.forEach(file => {
            this.getDuration(file.src)
                .then(function (length) {
                    const momentTime = +length * 1000;
                    file.duration = moment.utc(momentTime).format('mm:ss');
                });
        });

        // listen to stream state
        this.audioService.getState()
            .subscribe(state => {
                this.state = state;
            });

        this.openFile(this.musicTitles[0], 0);
    }

    getUrl(cd: boolean): string {
        if (cd) {
            return this.currentFile.file.cd;
        } else {
            return this.currentFile.file.cover;
        }
    }

    getDuration(src) {
        return new Promise(function (resolve) {
            const audio = new Audio();
            audio.onloadedmetadata = function (e) {
                resolve(audio.duration);
            };
            audio.src = src;
        });
    }

    playStream(url) {
        this.audioService.playStream(url)
            .subscribe((event: Event) => {
                if (event.type === 'ended') {
                    this.playerItemEnd();
                }
            });
    }

    openFile(file, index) {
        this.currentFile = { index, file };
        this.audioService.stop();
        this.playStream(file.src);
    }

    pause() {
        this.audioService.pause();
    }

    play() {
        this.audioService.play();
    }

    loadOrPlay(idx) {
        if (idx === this.currentFile.index) {
            this.play();
        } else {
            this.openFile(this.files[idx], idx);
            this.play();
        }
    }

    stop() {
        this.audioService.stop();
    }

    next() {
        this.loadOrPlay(this.currentFile.index + 1);
    }

    previous() {
        this.loadOrPlay(this.currentFile.index - 1);
    }

    playerItemEnd() {
        if (!this.isLastPlaying()) {
            this.next();
        } else {
            this.loadOrPlay(0);
        }
    }

    isFirstPlaying() {
        return this.currentFile.index === 0;
    }

    isLastPlaying() {
        return this.currentFile.index === this.files.length - 1;
    }

    getVolume() {
        return this.audioService.getVolume();
    }

    toggleVolume() {
        this.audioService.toggleVolume();
    }

    isMuted() {
        return this.audioService.isMuted();
    }

    onSliderChangeEnd(change) {
        this.audioService.seekTo(change.srcElement.value);
    }

    onVolumeChangeEnd(change) {
        this.audioService.setVolume(change.srcElement.value);
    }
}
