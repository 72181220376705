<!-- START HEADER SECTION -->
<section id="header" [style.height.px]="height">
    <!-- START MENU SECTION -->
    <div class="nav-menu nav-mobile" [ngClass]="{'active': menuClicked}">
        <!-- NAV BAR BACKGROUND IMAGE-->
        <div class="section_bg_image" style="background-image: url(./assets/img/bg_menu_sansibar.jpg)"
            *ngIf="!isMobile"></div>
        <div class="section_bg_image" style="background-image: url(./assets/img/bg_menu_sansibar_mobile.jpg)"
            *ngIf="isMobile"></div>

        <!-- START NAV BAR-->
        <ul class="nav flex-column section" app-menu (menuItemClicked)="closeMenu()"></ul>
        <!-- END NAV BAR-->

        <div class="overly"></div>
    </div>
    <!-- END MENU SECTION -->

    <!-- START SEARCH BAR SECTION -->
    <!-- <div class="search-bar"> -->

    <!-- SEARCH BAR BACKGROUND IMAGE-->
    <!-- <div class="section_bg_image" style="background-image: url(./assets/img/bg_menu_sansibar.jpg)"></div>

        <form class="form-inline my-2 my-lg-0 search_form" action="18-Search-Result-Page.html">
            <input class="form-control mr-sm-2" type="search" placeholder="Search..." aria-label="Search">
            <button class="btn btn-outline-success my-2 my-sm-0" type="submit">
                <svg class="open-search" viewBox="0 0 56.966 56.966" width="30px" height="30px">
                    <path
                        d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
                        fill="#FFFFFF" />
                </svg>
            </button>
        </form>

        <div class="overly"></div>
    </div> -->
    <!-- END SEARCH BAR SECTION  -->

    <!-- HEADER BACKGROUND IMAGE  -->
    <div *ngIf="isHome" class="bg_image" style="background-image: url(./assets/img/bg_image.jpg)"></div>

    <div class="header-continer" [ngClass]="{'active': !isTop && !menuClicked}">
        <div class="section">
            <!-- LOGO -->
            <div class="logo">
                <a routerLink="home">
                    <img class="white-logo" *ngIf="menuClicked || isHome && isTop" src="./assets/img/logo_white.png"
                        alt="Logo">
                    <img class="phink-logo" *ngIf="!menuClicked && (!isHome || !isTop)"
                        src="./assets/img/logo_black.png" alt="Logo">
                </a>
            </div>
            <div class="nav-menu nav-desktop" [ngClass]="{'active': menuClicked}">
                <!-- START NAV BAR-->
                <ul class="nav flex-column" app-menu></ul>
                <!-- END NAV BAR-->
            </div>
            <div class="header_items">
                <!-- <div class="search_icon">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <svg class="open-search" viewBox="0 0 56.966 56.966" width="30px" height="30px">
                        <path
                            d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
                            fill="#FFFFFF" />
                    </svg>
                    <svg class="close-search" xmlns="http://www.w3.org/2000/svg" version="1.1"
                        viewBox="0 0 15.642 15.642" enable-background="new 0 0 15.642 15.642" width="21" height="21">
                        <path fill-rule="evenodd"
                            d="M8.882,7.821l6.541-6.541c0.293-0.293,0.293-0.768,0-1.061  c-0.293-0.293-0.768-0.293-1.061,0L7.821,6.76L1.28,0.22c-0.293-0.293-0.768-0.293-1.061,0c-0.293,0.293-0.293,0.768,0,1.061  l6.541,6.541L0.22,14.362c-0.293,0.293-0.293,0.768,0,1.061c0.147,0.146,0.338,0.22,0.53,0.22s0.384-0.073,0.53-0.22l6.541-6.541  l6.541,6.541c0.147,0.146,0.338,0.22,0.53,0.22c0.192,0,0.384-0.073,0.53-0.22c0.293-0.293,0.293-0.768,0-1.061L8.882,7.821z"
                            fill="#FFFFFF" />
                    </svg>
                </div> -->
                <div class="menu_icon">
                    <fa-icon [icon]="faBars" size="lg" [hidden]="menuClicked" (click)="menuClicked = !menuClicked"
                        aria-hidden="true" [style.color]="getFontColor()">
                    </fa-icon>
                    <fa-icon [icon]="faTimes" size="lg" [hidden]="!menuClicked" (click)="menuClicked = !menuClicked"
                        aria-hidden="true" [style.color]="getFontColor()">
                    </fa-icon>
                </div>
            </div>
        </div>
    </div>

    <!-- HEADER TITLE  -->
    <!-- <div *ngIf="isHome" class="section_1_title">
        <div class="big-title">
            <div class="header-title section wow zoomIn">
                <h1>a music theme for you</h1>
                <a href="#">Get it now</a>
            </div>
            <h1>Ladan | Music</h1>
            <img src="resources/images/logo-big-white.png" alt="Logo 1">
        </div>
    </div> -->
</section>
<!-- END HEADER SECTION -->