<li *ngFor="let menuItem of navJson" class="nav-item">
    <a *ngIf="menuItem.link" class="nav-link" routerLink="{{menuItem.link}}" routerLinkActive="active" (click)="onClick()">{{menuItem.name}}</a>
    <a *ngIf="!menuItem.link" class="nav-link" [attr.href]="getSafeLink(menuItem.link)">{{menuItem.name}}</a>

    <ul *ngIf="menuItem.children?.length > 0" class="child-item">
        <li *ngFor="let childItem of menuItem.children" class="dropdown-child-item">
            <a *ngIf="childItem.link" class="nav-link" routerLink="{{childItem.link}}" routerLinkActive="active" (click)="onClick()">{{childItem.name}}</a>
            <a *ngIf="!childItem.link" class="nav-link" [attr.href]="getSafeLink(childItem.link)">{{childItem.name}}</a>
        </li>
    </ul>
</li>