import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgwWowModule } from 'ngx-wow';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { CoreModule } from './core/core.module';
import { ContactPageComponent } from './pages/contact/contact-page.component';
import { VideosComponent } from './pages/videos/videos.component';
import { AboutMePageComponent } from './pages/about-me/aboutme.component';
import { ErrorHandlerService } from './core/services/errorHandler.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    VideosComponent,
    ContactPageComponent,
    AboutMePageComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    HttpClientModule,
    NgwWowModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
