import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent {
    @Input() json: any;

    over(pictureItem: any) {
        pictureItem.hover = true;
    }

    out(pictureItem: any) {
        pictureItem.hover = false;
    }

    getTextPhotographer(photographer: string) {
        if (!photographer) {
            return null;
        }
        return 'Photo by: ' + photographer;
    }
}
