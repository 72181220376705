<footer id="footer">
    <div class="bg_image"></div>
    <div class="footer-overly"></div>
    <div class="section">
        <div class="row footer">
            <div class="col">
                <div class="folow">
                    <ul>
                        <li><a [href]="facebookLink" target="_blank">
                                <fa-icon [icon]="faFacebook"></fa-icon>
                            </a></li>
                        <li><a [href]="instagramLink" target="_blank">
                                <fa-icon [icon]="faInstagram"></fa-icon>
                            </a></li>
                        <li><a [href]="youtubeLink" target="_blank">
                                <fa-icon [icon]="faYoutube"></fa-icon>
                            </a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row f_1">
            <div class="col">
                <div class="copyright">
                    <span [innerText]="text"></span>
                </div>
            </div>
        </div>
    </div>
</footer>