import { Component, HostListener } from '@angular/core';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class PageNotFoundComponent {
    title = 'not-found';
    height: number;

    constructor() {
        this.onResize();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.height = window.innerHeight;
    }
}
