import { Component } from '@angular/core';

import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

import Json from '../../../../assets/data/footer.json';
import Links from '../../../../assets/data/links.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  title = 'footer';
  public text = Json.text + (new Date()).getFullYear();

  facebookLink = Links.facebook;
  faFacebook = faFacebook;
  instagramLink = Links.instagram;
  faInstagram = faInstagram;
  youtubeLink = Links.youtube;
  faYoutube = faYoutube;
}
