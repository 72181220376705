import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './core/components/not-found/not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactPageComponent } from './pages/contact/contact-page.component';
import { VideosComponent } from './pages/videos/videos.component';
import { AboutMePageComponent } from './pages/about-me/aboutme.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'Home',
      bodyClass: 'home'
    }
  },
  {
    path: 'aboutme',
    component: AboutMePageComponent,
    data: {
      title: 'About Me',
      bodyClass: 'about-me-page'
    }
  },
  {
    path: 'videos',
    component: VideosComponent,
    data: {
      title: 'Videos',
      bodyClass: 'videos'
    }
  },
  {
    path: 'contact',
    component: ContactPageComponent,
    data: {
      title: 'Contact',
      bodyClass: 'contact-us-page'
    }
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      title: 'Not Found',
      bodyClass: 'page-404'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
