import { Component } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

import { DeviceDetectorService } from 'ngx-device-detector';
import { NgwWowService } from 'ngx-wow';

import { MyMonitoringService } from './core/services/logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'frontend';
  bodyClass = '';
  browser = '';
  isIE = false;

  constructor(private router: Router, private deviceService: DeviceDetectorService, private wowService: NgwWowService) {
    wowService.init();
    // listen to page variable from router events
    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        // tslint:disable-next-line:no-shadowed-variable
        const route = event.state.root.firstChild;
        this.bodyClass = route.data.bodyClass || '';
      }
    });

    if (deviceService.browser === 'IE') {
      this.browser = 'msie';
      this.isIE = true;
    } else if (deviceService.browser === 'Chrome' || deviceService.browser === 'MS-Edge-Chromium') {
      this.browser = 'chrome';
    } else if (deviceService.browser === 'Firefox') {
      this.browser = 'firefox';
    } else if (deviceService.browser === 'Safari') {
      this.browser = 'safari';
    } else if (deviceService.browser === 'Opera') {
      this.browser = 'opera';
    } else if (deviceService.browser === 'Edge') {
      this.browser = 'edge';
    }
  }
}
