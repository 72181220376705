<section id="testimonials" class="section_7">
    <div class="section">
        <div class="title">
            <div>
                <h1 [innerText]="data?.title"></h1>
                <p *ngIf="data?.description" [innerText]="data?.description"></p>
            </div>
        </div>
        <ngb-carousel *ngIf="data?.testimonials" [showNavigationIndicators]="data?.testimonials?.length > 1">
            <ng-template ngbSlide *ngFor="let testimonial of data?.testimonials">
                <div class="testimonial-content">
                    <a *ngIf="testimonial.picture" href="" class="slide_img">
                        <img [src]="testimonial.picture" alt="Testimonial">
                    </a>
                    <div class="carousel_content">
                        <p [innerText]="testimonial.text"></p>
                    </div>
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</section>