<section id="contact-us" class="section_8" style="background-image: url(./assets/img/bg_contact-me.jpg)">
    <div class="overly"></div>
    <div class="section">
        <div class="title">
            <div>
                <h1 [innerText]="header"></h1>
                <p [innerText]="description"></p>
            </div>
        </div>
        <div class="form">
            <form [formGroup]="emailForm" (ngSubmit)="submitForm()" *ngIf="!formSubmited">
                <div class="row fild_1">
                    <div class="col-sm-6">
                        <input type="text" class="form-control name" placeholder="Name" formControlName="name"
                            [ngClass]="{'has-error': emailForm.get('name').invalid && (emailForm.get('name').dirty || emailForm.get('name').touched)}">
                    </div>
                    <div class="col-sm-6">
                        <input type="email" class="form-control email" placeholder="Email" formControlName="email"
                            [ngClass]="{'has-error': emailForm.get('email').invalid && (emailForm.get('email').dirty || emailForm.get('email').touched)}">
                    </div>
                </div>
                <div class="form-group">
                    <textarea class="form-control message" rows="3" placeholder="Message" formControlName="message"
                        [ngClass]="{'has-error': emailForm.get('message').invalid && (emailForm.get('message').dirty || emailForm.get('message').touched)}"></textarea>
                </div>
                <div *ngIf="formInvalid">
                    <p class="warning">Please fill out the required fields!</p>
                </div>
                <button type="submit" class="btn btn-primary">Send</button>
            </form>
            <div *ngIf="formSubmited">
                <p class="submited">Thanks for your Message, we will send you a response as soon as possible!</p>
            </div>
        </div>
    </div>
</section>