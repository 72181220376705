import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  title = 'nav';
  isHome = false;
  isTop = true;
  height: number;
  menuClicked = false;
  faBars = faBars;
  faTimes = faTimes;
  isMobile = false;

  constructor(private router: Router, private deviceService: DeviceDetectorService) {
    this.isMobile = deviceService.isMobile();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isHome = false;
        if (event.url === '/' || event.url === '/home') {
          this.isHome = true;
          this.onResize();
        } else {
          this.isHome = false;
          this.height = null;
        }
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (this.isHome) {
      this.height = window.innerHeight;
    }
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;

    if (windowScroll > 0) {
      this.isTop = false;
    } else {
      this.isTop = true;
    }
  }

  getFontColor() {
    if (this.menuClicked || (this.isHome && this.isTop)) {
      return '#fff';
    } else {
      return '#1b1b1b';
    }
  }

  closeMenu() {
    this.menuClicked = false;
  }
}
