import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import Json from '../../../../assets/data/home/video.json';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent {
    title = 'video-player';
    playClicked = false;

    public header = Json.title;
    public description = Json.description;
    public backgroundPicture = Json.backgroundPicture;
    public videoPicture = Json.videoPicture;
    public youTubeVideoId = Json.youTubeVideoId;
    public videoUrl: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer) {
        // Appending an ID to a YouTube URL is safe.
        // Always make sure to construct SafeValue objects as
        // close as possible to the input data so
        // that it's easier to check if the value is safe.
        const dangerousVideoUrl = 'https://www.youtube.com/embed/' + this.youTubeVideoId + '?autoplay=1';
        this.videoUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(dangerousVideoUrl);
    }
}
