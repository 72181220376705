<section id="header-404" [style.height.px]="height">
    <!-- HEADER BACKGROUND IMAGE  -->
    <!-- <div class="bg_image" style="background-image: url(assets/img/william-iven-17136-unsplash-404.png)"></div> -->
    <div class="overly"></div>
    <div class="section section-404">
        <div class="big-title">
            <img src="assets/img/404.png" alt="Logo 1">
            <h2>Upps! nothing found</h2>
        </div>
        <div class="content-404">
            <h2>Sorry!</h2>
            <p>The page you are looking for does not exist or another error occurred</p>
        </div>
        <!-- <div class="search-404">
            <form class="form-inline md-form form-sm">
                <input class="mr-sm-2" type="text" placeholder="Type any keyword" aria-label="">
                <button class="search-btn" type="submit">SEARCH</button>
            </form>
        </div> -->
        <div class="go-back-home">
            <a routerLink="/home"><i class="fa fa-home" aria-hidden="true"></i><span>GO BACK HOME</span></a>
        </div>
    </div>
</section>