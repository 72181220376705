import { Component } from '@angular/core';

import GalleryJson from '../../../assets/data/home/gallery.json';
import TestimonialJson from '../../../assets/data/home/testimonial.json';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent {
    title = 'home';

    public galleryJson = GalleryJson;
    public testimonialJson = TestimonialJson;
}
