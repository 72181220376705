<section id="gallery" class="section_4">
    <!-- IMAGE GALLERY TITLE -->
    <div class="title">
        <div>
            <h1 [innerText]="json.title"></h1>
            <p *ngIf="json.description" [innerText]="json.description"></p>
        </div>
    </div>
    <div id="image-gallery" class="row img_gallery">
        <div class="row">
            <div class="col-md-4 music-banner" #img [ngStyle]="{'height': img.getBoundingClientRect().width + 'px' }"
                *ngFor="let pictureItem of json.pictures; let idx = index" (mouseover)='over(pictureItem)'
                (mouseout)='out(pictureItem)'>
                <a href="javascript:void(0)" class="anim_border"></a>
                <a href="" class="img_link">
                    <img [src]="pictureItem.src" alt="Gallery Image {{idx}}">
                </a>
                <div class="photographer" [innerText]="getTextPhotographer(pictureItem.photographer)"
                    *ngIf="pictureItem.hover"></div>
            </div>
        </div>
        <div id="popup-container">
            <div class="popup_block" id="popup_block">
                <div id="slides-container"></div>
                <div class="popup_left popup_arrow">
                    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                </div>
                <div class="popup_right popup_arrow">
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                </div>
            </div>
            <ul></ul>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col cta">
            <a href="08-Gallery-Photogallery.html">More Photos</a>
        </div>
    </div> -->
</section>