<section id="video" class="section_5">
    <div class="section_bg_image" [style.background-image]="'url(' + backgroundPicture + ')'"></div>
    <div class="section">
        <div class="title">
            <div>
                <h1 [innerText]="header"></h1>
                <p [innerText]="description"></p>
            </div>
        </div>
        <div class="video">
            <div class="embed-responsive embed-responsive-16by9">
                <div *ngIf="!playClicked" class="youtube" id="{{youTubeVideoId}}" [attr.data-src]="videoPicture">
                    <img [src]="videoPicture" class="thumb" />
                    <div class="play" (click)="playClicked = !playClicked"></div>
                </div>
                <iframe *ngIf="playClicked" [src]="videoUrl"></iframe>
            </div>
        </div>
    </div>
</section>