import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { EmailService } from '../../services/email.service';
import { MyMonitoringService } from '../../services/logging.service';

import ContactJson from '../../../../assets/data/contact.json';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  title = 'contact';
  public header = ContactJson.title;
  public description = ContactJson.description;

  emailForm: FormGroup;
  formInvalid = false;
  formSubmited = false;

  constructor(private fb: FormBuilder, private myMonitoringService: MyMonitoringService, private emailService: EmailService) {
    this.emailForm = fb.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      message: [null, Validators.required]
    });
  }

  submitForm() {
    if (this.emailForm.invalid) {
      this.formInvalid = true;
      return;
    }

    this.emailService.SendMail(this.emailForm.value.name, this.emailForm.value.email, this.emailForm.value.message).subscribe(() =>
      this.myMonitoringService.logEvent('Mail Send', {
        'name': this.emailForm.value.name,
        'email': this.emailForm.value.email,
        'message': this.emailForm.value.message
      }));
    this.formSubmited = true;
  }
}
