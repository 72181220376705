import { Component, OnInit } from '@angular/core';
import { YoutubeService } from 'src/app/core/services/youtube.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './videos.component.html',
    styleUrls: ['./videos.component.scss']
})

export class VideosComponent implements OnInit {
    title = 'videos';
    videos: any[];
    channel = 'UCY3bB1Gj8IyrdRgA-2lwG6Q';

    private unsubscribe$: Subject<any> = new Subject();

    constructor(private youTubeService: YoutubeService) { }

    ngOnInit() {
        this.videos = [];
        this.youTubeService
            .getVideosForChanel(this.channel, 15)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(lista => {
                for (const element of lista['items']) {
                    this.videos.push(element);
                }
            });
    }
}
