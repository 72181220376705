import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import { catchError, shareReplay, retryWhen, mergeMap, delay } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { MyMonitoringService } from './logging.service';

const getErrorMessage = (maxRetry: number) => `Tried to load Resource over XHR for ${maxRetry} times without success. Giving up.`;

const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_BACKOFF = 1000;

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient, private myMonitoringService: MyMonitoringService) { }

  SendMail(name: string, email: string, message: string) {
    let url = 'https://prod-119.westeurope.logic.azure.com:443/workflows/9c35fe289be843d0bb9c98156da1181f/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=' + environment.LOGICAPP_SIG;

    const body = {
      'emailbody': message,
      'from': email,
      'name': name
    };

    return this.http.post(url, body).pipe(
      retryWithBackoff(500),
      catchError(error => {
        this.myMonitoringService.logException(error);
        return 'SendMail Error';
      }),
      shareReplay()
    );
  }
}

export function retryWithBackoff(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES, backoffMs = DEFAULT_BACKOFF) {
  let retries = maxRetry;

  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        mergeMap(error => {
          if (retries-- > 0) {
            const backoffTime = delayMs + (maxRetry - retries) * backoffMs;
            return of(error).pipe(delay(backoffTime));
          }
          return throwError(getErrorMessage(maxRetry));
        })
      ))
    );
}
