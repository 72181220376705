import { Component } from '@angular/core';

import Json from '../../../assets/data/about-me.json';

@Component({
    selector: 'app-about-me-page',
    templateUrl: './aboutme.component.html',
    styleUrls: ['./aboutme.component.scss']
})
export class AboutMePageComponent {
    public title = Json.title;
    public subtitle = Json.subtitle;
    public text = Json.text.join('');
}
