<app-page-title pageTitle="YouTube"></app-page-title>

<section id="videos">
    <div class="section">
        <div class="row">
            <div *ngFor="let video of videos" class="w-100 col-md-6 col-lg-4 col-xl-3 video-block">
                <div class="w-100">
                    <div class="video-img-banner">
                        <a href="https://youtu.be/{{video.id.videoId}}" target="_blank">
                            <img src="{{video.snippet.thumbnails.high.url}}" alt="maxresdefault">
                            <div class="play"></div>
                        </a>
                    </div>
                    <a href="https://youtu.be/{{video.id.videoId}}" target="_blank">
                        <div class="video-banner-name">
                            <h2 [innerText]="video.snippet.title"></h2>
                            <p [innerText]="video.snippet.description"></p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>