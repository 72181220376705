<section id="album-release" class="section_2">
    <div class="section">
        <!-- ALBUM RELEASE TITLE -->
        <div class="title">
            <div class="">
                <h1 [innerText]="header"></h1>
                <p *ngIf="description" [innerText]="description"></p>
            </div>
        </div>
        <!-- ALBUM RELEASE MUSIC -->
        <div class="row j_c">
            <div class="col cd_block wow fadeInLeftBig">
                <div class="disc_cover">
                    <img class="disc" [src]="getUrl(false)">
                    <img class="cd" [src]="getUrl(true)">
                </div>
            </div>
            <div class="col music wow fadeInRightBig">
                <div class="mp3">
                    <div tabindex="0" class="plyr plyr--audio plyr--ready"
                        [ngClass]="{'plyr--stopped': !state?.playing, 'plyr--playing': state?.playing, 'plyr--muted': isMuted(), 'plyr--is-ios': isIos}">
                        <audio class="audio1 plyr--setup">
                            <source type="audio/mpeg" [src]="currentFile.file">
                        </audio>
                        <div class="plyr__controls">
                            <button type="button" class="play-pause" aria-label="Play" (click)="play()"
                                *ngIf="!state?.playing">
                                <fa-icon class="play" [icon]="faPlay"></fa-icon>
                                <span class="plyr__sr-only">Play</span>
                            </button>
                            <button type="button" class="play-pause" (click)="pause()" *ngIf="state?.playing">
                                <fa-icon class="pause" [icon]="faPause"></fa-icon>
                                <span class="plyr__sr-only">Pause</span>
                            </button>
                            <span class="plyr__progress">
                                <label for="seek" class="plyr__sr-only">Seek</label>
                                <input id="seek" class="plyr__progress--seek" type="range" min="0"
                                    [max]="state?.duration" step="1" [value]="state?.currentTime"
                                    (input)="onSliderChangeEnd($event)"
                                    [disabled]="state?.error || currentFile.index === undefined">
                                <progress class="plyr__progress--played" max="100" value="0"
                                    role="presentation"></progress>
                                <!-- <progress class="plyr__progress--buffer" max="100" value="50.8">
                                    <span>50.80</span>% buffered
                                </progress> -->
                                <span class="plyr__tooltip">00:00</span>
                            </span>
                            <span class="plyr__time">
                                <span class="plyr__sr-only">Current time</span>
                                <span class="plyr__time--current" [innerText]="state?.readableCurrentTime">00:00</span>
                            </span>
                            <span class="plyr__time">
                                <span class="plyr__sr-only">Duration</span>
                                <span class="plyr__time--duration" [innerText]="state?.readableDuration">00:00</span>
                            </span>
                            <button type="button" class="mute" data-plyr="mute" [attr.aria-pressed]="!isMuted()"
                                (click)="toggleVolume()">
                                <fa-icon class="volumeOff" [icon]="faVolumeOff"></fa-icon>
                                <fa-icon class="volumeMute" [hidden]="isMuted()" [icon]="faVolumeMute"></fa-icon>
                                <fa-icon class="volumeUp" [hidden]="!isMuted()" [icon]="faVolumeUp"></fa-icon>
                                <span class="plyr__sr-only">Toggle Mute</span>
                            </button>
                            <span class="plyr__volume">
                                <label for="volume" class="plyr__sr-only">Volume</label>
                                <input id="volume" class="plyr__volume--input" type="range" min="0" max="1" step="0.1"
                                    value="1" data-plyr="volume" [value]="getVolume()"
                                    (input)="onVolumeChangeEnd($event)">
                                <progress class="plyr__volume--display" max="1" value="1"
                                    role="presentation"></progress>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="playlist" id="playlist">
                    <div class="music_line" [ngClass]="'mus-'+idx"
                        *ngFor="let musicItem of musicTitles; let idx = index">
                        <div class="play-pause" data-src="musicItem.src">
                            <fa-icon class="play-mp3" [icon]="faPlay"
                                *ngIf="currentFile.index !== idx || (currentFile.index === idx && !state?.playing)"
                                (click)="loadOrPlay(idx)"></fa-icon>
                            <fa-icon class="pause-mp3" [icon]="faPause"
                                *ngIf="currentFile.index === idx && state?.playing" (click)="pause()"></fa-icon>
                        </div>
                        <span class="music-name" [innerText]="(idx+1) + '. ' + musicItem.name"></span>
                        <div class="player-grig"></div>
                        <span class="plyr__time" [hidden]="isIos">
                            <span class="plyr__time--duration" [innerText]="files[idx].duration"></span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="sec_2_content">
            <p [innerHTML]="text"></p>
        </div>
        <div class="streaming-platforms wow bounceIn">
            <a href="https://open.spotify.com/artist/7f81bEpMH1euM2LYWVXIS4?si=rTooYD1UTqy6EgwYgRYZNw" target="_blank"
                placement="bottom" ngbTooltip="Spotify">
                <fa-icon [icon]="faSpotify" size="2x"></fa-icon>
            </a>
            <a href="https://music.apple.com/ch/album/running-single/1514785047" target="_blank" placement="bottom"
                ngbTooltip="iTunes">
                <fa-icon [icon]="faItunes" size="2x"></fa-icon>
            </a>
        </div>
    </div>
</section>