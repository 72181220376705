import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbCarouselModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { NavComponent } from './components/nav/nav.component';
import { MenuComponent } from './components/nav/menu/menu.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageNotFoundComponent } from './components/not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { MusicPlayerComponent } from './components/music-player/music-player.component';
import { VideoComponent } from './components/video/video.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { TestimonialComponent } from './components/testimonials/testimonial.component';

// import { MetaLoader, MetaModule, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
// import { TranslateModule, TranslateService } from '@ngx-translate/core';

// export function metaFactory(): MetaLoader {
//   return new MetaStaticLoader({
//     pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
//     pageTitleSeparator: ' - ',
//     applicationName: 'Ladan | Music',
//     defaults: {
//       title: 'Dienstjournal',
//       description: 'Dienstjournal 2.0'
//     }
//   });
// }

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        NgbCarouselModule,
        NgbTooltipModule,
        FontAwesomeModule
        // MetaModule.forRoot({
        //   provide: MetaLoader,
        //   useFactory: (metaFactory)
        // }),
        // TranslateModule
    ],
    declarations: [
        NavComponent,
        MenuComponent,
        MusicPlayerComponent,
        VideoComponent,
        GalleryComponent,
        TestimonialComponent,
        ContactComponent,
        FooterComponent,
        PageTitleComponent,
        PageNotFoundComponent
    ],
    exports: [
        CommonModule,
        NavComponent,
        MenuComponent,
        MusicPlayerComponent,
        VideoComponent,
        GalleryComponent,
        TestimonialComponent,
        ContactComponent,
        FooterComponent,
        PageTitleComponent,
        PageNotFoundComponent
    ],
    providers: [
        NgbCarouselConfig
        // { provide: ErrorHandler, useClass: GlobalErrorHandler }
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule, config: NgbCarouselConfig) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }

        // customize default values of carousels used by this component tree
        config.interval = 10000;
        config.wrap = true;
        config.keyboard = false;
        config.pauseOnHover = true;
        config.showNavigationArrows = false;
        config.showNavigationIndicators = true;
    }
}
